import React from "react";
import { concatClassNames as cn } from "@system42/core";
import {
  ButtonPrimaryLarge,
  Headline1Sans,
  Overline,
  urlCreateAccount,
} from "../../system42";
import { FeatureIcon } from "../FeatureIcon";
import * as styles from "./styles.module.css";

export function HeaderOverlapDoubleImage(props) {
  const {
    featureIcon,
    featureIconAlt,
    overline,
    title,
    titleWidth,
    buttonSubtext,
    className,
    image1Src,
    image1Alt,
    image2Src,
    image2Alt,
    c2aButtonLabel,
    ...containerProps
  } = props;

  return (
    <header
      className={cn(className, styles.headerOverlapDoubleImage)}
      {...containerProps}
    >
      <div className={styles.top}>
        {featureIcon && (
          <FeatureIcon className={styles.featureIcon}>
            <img src={featureIcon} alt={featureIconAlt} />
          </FeatureIcon>
        )}
        <Overline className={styles.overline}>{overline}</Overline>
        <Headline1Sans
          className={styles.title}
          style={{ maxWidth: titleWidth }}
        >
          {title}
        </Headline1Sans>
        <ButtonPrimaryLarge
          className={styles.button}
          customTag={"a"}
          href={urlCreateAccount}
        >
          {c2aButtonLabel || "Start free trial"}
        </ButtonPrimaryLarge>
        <div className={styles.buttonSubtext}>{buttonSubtext}</div>
      </div>
      <div className={styles.overlap}>
        <div className={styles.images}>
          <img className={styles.image1} src={image1Src} alt={image1Alt} />
          <img className={styles.image2} src={image2Src} alt={image2Alt} />
        </div>
      </div>
    </header>
  );
}
